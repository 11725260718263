import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-instagram-button',
  templateUrl: './instagram-button.component.html',
  styleUrls: ['./instagram-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstagramButtonComponent {

}
