import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-social-nav',
  templateUrl: './social-nav.component.html',
  styleUrls: ['./social-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialNavComponent {

  @Input()
  light: boolean;

}
