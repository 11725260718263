import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { NguCarouselModule } from '@ngu/carousel';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { ApostropheComponent } from './components/apostrophe/apostrophe.component';
import { AppStoreButtonComponent } from './components/app-store-button/app-store-button.component';
import { AppStoreDialogComponent } from './components/app-store-dialog/app-store-dialog.component';
import { FacebookButtonComponent } from './components/facebook-button/facebook-button.component';
import { FeaturedImageComponent } from './components/featured-image/featured-image.component';
import { FormFinishScreenComponent } from './components/form-finish-screen/form-finish-screen.component';
import { GooglePlayButtonComponent } from './components/google-play-button/google-play-button.component';
import { GooglePlayDialogComponent } from './components/google-play-dialog/google-play-dialog.component';
import { InstagramButtonComponent } from './components/instagram-button/instagram-button.component';
import { LogoComponent } from './components/logo/logo.component';
import { PreloadImageDirective } from './directives/preload-image.directive';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { SocialNavComponent } from './components/social-nav/social-nav.component';
import { StoreButtonsComponent } from './components/store-buttons/store-buttons.component';
import { TwitterButtonComponent } from './components/twitter-button/twitter-button.component';
import { ValuesHeaderComponent } from './components/values-header/values-header.component';
import { YoutubeButtonComponent } from './components/youtube-button/youtube-button.component';

@NgModule({
  declarations: [
    ApostropheComponent,
    AppStoreButtonComponent,
    AppStoreDialogComponent,
    FacebookButtonComponent,
    FeaturedImageComponent,
    FormFinishScreenComponent,
    GooglePlayButtonComponent,
    GooglePlayDialogComponent,
    InstagramButtonComponent,
    LogoComponent,
    PreloadImageDirective,
    ScrollTopComponent,
    SocialNavComponent,
    StoreButtonsComponent,
    TwitterButtonComponent,
    ValuesHeaderComponent,
    YoutubeButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    NguCarouselModule,
    NgxPageScrollModule
  ],
  exports: [
    ApostropheComponent,
    AppStoreButtonComponent,
    CommonModule,
    FacebookButtonComponent,
    FeaturedImageComponent,
    FormFinishScreenComponent,
    GooglePlayButtonComponent,
    InstagramButtonComponent,
    LogoComponent,
    MatButtonModule,
    NguCarouselModule,
    NgxPageScrollModule,
    PreloadImageDirective,
    ScrollTopComponent,
    SocialNavComponent,
    StoreButtonsComponent,
    TwitterButtonComponent,
    ValuesHeaderComponent,
    YoutubeButtonComponent
  ],
  entryComponents: [AppStoreDialogComponent, GooglePlayDialogComponent]
})
export class SharedModule {}
