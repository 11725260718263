import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appPreloadImage]'
})
export class PreloadImageDirective {
  @Input('appPreloadImage') set imageSrc(imageSrc: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.srcChanged(imageSrc);
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  private srcChanged(imageSrc: string) {
    this.el.nativeElement.nodeName.toLowerCase() === 'img'
      ? this.loadImage(imageSrc)
      : this.loadImage(imageSrc, true);
  }

  private loadImage(imageSrc: string, isBackground: boolean = false) {
    this.renderer.addClass(this.el.nativeElement, 'image-load');
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => this.imageLoaded(imageSrc, isBackground);
  }

  private imageLoaded(imageSrc: string, isBackground: boolean) {
    if (isBackground) {
      this.renderer.setStyle(
        this.el.nativeElement,
        'background-image',
        `url(${imageSrc})`
      );
    }

    this.renderer.addClass(this.el.nativeElement, 'image-load--loaded');
  }
}
