import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggle', [
      state('hidden', style({ height: 0 })),
      state('Visible', style({ height: '*' })),
      transition('* => *', animate('300ms ease-in-out'))
    ])
  ]
})
export class HeaderComponent {
  visibilityState = VisibilityState.Hidden;

  onToggle() {
    this.visibilityState =
      this.visibilityState === VisibilityState.Hidden
        ? VisibilityState.Visible
        : VisibilityState.Hidden;
  }
}
