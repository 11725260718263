import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-store-buttons',
  templateUrl: './store-buttons.component.html',
  styleUrls: ['./store-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreButtonsComponent {

  @Input()
  light: boolean;

}
