import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-app-store-dialog',
  templateUrl: './app-store-dialog.component.html',
  styleUrls: ['./app-store-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppStoreDialogComponent {

}
