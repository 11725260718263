import {
  Component,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'app-apostrophe',
  templateUrl: './apostrophe.component.html',
  styleUrls: ['./apostrophe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApostropheComponent {}
