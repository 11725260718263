import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-app-store-button',
  templateUrl: './app-store-button.component.html',
  styleUrls: ['./app-store-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppStoreButtonComponent {
  @Input()
  light: boolean;

  constructor() {}
}
