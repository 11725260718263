import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-google-play-button',
  templateUrl: './google-play-button.component.html',
  styleUrls: ['./google-play-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GooglePlayButtonComponent implements OnInit {
  @Input()
  light: boolean;

  absoluteUri: string;
  linearGradientId1: string;
  linearGradientId2: string;
  linearGradientId3: string;
  linearGradientId4: string;

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.absoluteUri = this.document.location.href;
    this.linearGradientId1 = this.newGuid();
    this.linearGradientId2 = this.newGuid();
    this.linearGradientId3 = this.newGuid();
    this.linearGradientId4 = this.newGuid();
  }

  private newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line:no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
