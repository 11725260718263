import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: 'about', loadChildren: './about/about.module#AboutModule' },
  {
    path: 'influencers',
    loadChildren: './influencers/influencers.module#InfluencersModule'
  },
  {
    path: 'sponsor',
    loadChildren: './sponsors/sponsors.module#SponsorsModule'
  },
  {
    path: 'how-to-play',
    loadChildren: './how-to-play/how-to-play.module#HowToPlayModule'
  },
  {
    path: 'we-give-a-shoe-program',
    loadChildren: './shoe-program/shoe-program.module#ShoeProgramModule'
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
