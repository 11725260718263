import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-featured-image',
  templateUrl: './featured-image.component.html',
  styleUrls: ['./featured-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedImageComponent {

  @Input()
  title: string;

  @Input()
  image: string;

}
