import {
  Component,
  PLATFORM_ID,
  Inject,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PageScrollConfig } from 'ngx-page-scroll';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    PageScrollConfig.defaultDuration = 500;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && document) {
      AOS.init();
    }
  }

  ngAfterViewInit() {
    this.updateAos();
  }

  onActivate() {
    if (isPlatformBrowser(this.platformId) && window) {
      window.scrollTo(0, 0);
    }
    this.updateAos();
  }

  private updateAos() {
    if (isPlatformBrowser(this.platformId) && document) {
      setTimeout(() => {
        AOS.refresh();
      }, 500);
    }
  }
}
