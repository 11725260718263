import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-youtube-button',
  templateUrl: './youtube-button.component.html',
  styleUrls: ['./youtube-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeButtonComponent {

}
