import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-google-play-dialog',
  templateUrl: './google-play-dialog.component.html',
  styleUrls: ['./google-play-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GooglePlayDialogComponent {

}
